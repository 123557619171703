// Entry point for the build script in your package.json

import { Turbo } from "@hotwired/turbo-rails";
import "./channels"
import "./jitsiRooms";

import "trix"
import "@rails/actiontext"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
window.Turbo = Turbo;