import jQuery from "./jquery.min";
const $ = jQuery;

$(document).ready(function () {
  const domain = "meet.jit.si";
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ref = urlParams.get("ref");
  const name = urlParams.get("name");

  const options = {
    height: "100%",
    width: "100%",
    roomName: gon.name,
    parentNode: document.querySelector(".meet"),
    userInfo: {
      displayName: name ? decodeURIComponent(name) : "",
      subject: "Blue Berry meeting"
    },
    configOverwrite: {
      toolbarButtons: [
           'camera',
           'chat',
           'closedcaptions',
           'desktop',
           'dock-iframe',
           'download',
           /*'embedmeeting',*/
           'etherpad',
           'feedback',
           'filmstrip',
           'fullscreen',
           'hangup',
           'help',
           'highlight',
           /*'invite',*/
           'linktosalesforce',
           'livestreaming',
           'microphone',
           'participants-pane',
           'profile',
           'raisehand',
           /*'recording',*/
           'security',
           'select-background',
           'settings',
           'shareaudio',
           'sharedvideo',
           'shortcuts',
           'stats',
           'tileview',
           'toggle-camera',
           'undock-iframe',
           'videoquality',
           '__end'
       ],
    }
  };
  const api = new JitsiMeetExternalAPI(domain, options);

  //propmpt password on join
  setTimeout(() => {
    api.addEventListener("videoConferenceJoined", (response) => {
      api.executeCommand("password", gon.password);
    });
  }, 50);

  //make sure this eventlistener gets created only after the above listener fires the first time
  //otherwise event gets fired on password prompt as user was "kicked" out of the lobby because password was detected
  setTimeout(() => {
    api.addListener("videoConferenceLeft", () => {
      window.location.replace(ref ? decodeURIComponent(ref) : "about:blank");
    });
  }, 5000);
});
